import React, { useEffect, useState } from 'react';
import Link from 'components/UI/Link';
import SocialMediaIcon from 'components/UI/SocialMedia/SocialMediaIcon';
import { isRequestedFrom, useTrans, useLang } from 'utils/localisation';
import * as styles from './Copyright.module.scss';

function Copyright() {
  const lang = useLang();
  const t = useTrans(['copyright', 'captcha']);

  const [andersenPolicyData, setAndersenPolicyData] = useState({
    link:
      lang === 'ae' || lang === 'az' ? 'https://andersenlab.com/privacy-policy' : '/privacy-policy',
    text: 'Privacy Policy',
  });

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (isRequestedFrom('BY')) {
      setAndersenPolicyData({
        link: 'https://andersenlab.com/personal_data_processing_policy.pdf',
        text: 'Privacy Policy',
      });
    }
  }, []);

  const cookiesPolicyLink =
    lang === 'ae' || lang === 'az' ? 'https://andersenlab.com/cookies-policy' : '/cookies-policy';

  return (
    <div className={styles.wrapper}>
      <div className={styles.layout}>
        <div className={styles.copyright}>
          <div className={styles.textSection}>
            <p className={styles.text}>
              <span className={styles.icon}>©</span>
              {`${currentYear} ${t('companyName')} All Rights Reserved. \n`}
              <Link
                className={styles.link}
                href={andersenPolicyData.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {andersenPolicyData.text}
              </Link>
              {' & '}
              <Link
                className={styles.link}
                href={cookiesPolicyLink}
                target="_blank"
                rel="noreferrer noopener"
              >
                Cookies Policy.
              </Link>
            </p>
            <p className={styles.text}>
              This site is protected by reCAPTCHA and the Google{' '}
              <Link
                className={styles.link}
                href="https://policies.google.com/privacy"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                className={styles.link}
                href="https://policies.google.com/terms"
                rel="noreferrer noopener"
              >
                Terms of Service
              </Link>{' '}
              apply.
            </p>
            {lang === 'de' && (
              <p className={styles.text}>
                {` Information about the site owner`}{' '}
                <Link
                  className={styles.link}
                  href="/impressum"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Impressum.
                </Link>
              </p>
            )}
          </div>
          <div className={styles.socialSection}>
            <SocialMediaIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Copyright;
