import React, { useState } from 'react';
import cn from 'classnames';
import Link from 'components/UI/Link';
import SubMenu from '../SubMenu';
import { useHistoryCallback } from 'utils/routing';

import * as styles from './MainNav.module.scss';

const ItemWrapper = ({ item, children, ...props }) => {
  if (item?.to) {
    return (
      <Link href={item.to} state={{ activeTab: item.key }} {...props}>
        {children}
      </Link>
    );
  }

  return <div {...props}>{children}</div>;
};

function MainNav({ items = [], mods = [], currentLink, isIndexPage }) {
  const [openTab, setOpenTab] = useState();
  const [withTransition, setWithTransition] = useState(true);

  useHistoryCallback(setOpenTab);

  const handleTabOpen = (tab, shouldTrigger) => e => {
    if (shouldTrigger && openTab && tab) {
      setWithTransition(false);
    } else {
      setWithTransition(true);
    }

    if (e.type === 'touchstart' && e.target.id === tab && openTab === tab) {
      setWithTransition(true);
      setOpenTab();
      return;
    }
    if (shouldTrigger) {
      setOpenTab(tab);
    }
  };

  return (
    <div className={cn(styles.mainNav, ...mods.map(key => styles[key]))}>
      <div className={styles.list}>
        {items.map(item => {
          const isActive = getActiveTab(item.submenu, currentLink) || currentLink.includes(item.to);
          const isHovered = openTab === item.key;

          return (
            <div
              id={item.key}
              key={item.key}
              className={cn(
                styles.item,
                item?.submenu && styles.submenu,
                isHovered && styles.showSubMenu,
              )}
              tabIndex={item.key}
              role="button"
              onTouchStart={handleTabOpen(item.key, Boolean(item?.submenu))}
              onMouseEnter={handleTabOpen(item.key, Boolean(item?.submenu))}
              onMouseLeave={handleTabOpen(null, Boolean(item?.submenu))}
            >
              <ItemWrapper
                className={cn(
                  styles.link,
                  item?.submenu && styles.arrow,
                  item?.submenu && styles.touchDisabled,
                  !isIndexPage && isActive && styles.current,
                )}
                item={item}
              >
                {item.key}
                {item?.submenu && <div className={cn(styles.arrowIcon, styles.arrowRotated)} />}
              </ItemWrapper>
              {item?.submenu && (
                <SubMenu
                  active={isHovered}
                  theme={item.options?.theme}
                  parentLink={item?.parentLink}
                  currentLink={currentLink}
                  items={item.submenu}
                  withTransition={withTransition}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function getActiveTab(submenuItems, currentLink) {
  if (!submenuItems) return;

  const submenuLinks = submenuItems?.reduce((acc, item) => {
    if (item.url) {
      acc.push(item.url);
    }

    if (item.parentLink) {
      acc.push(item.parentLink.url);
    }

    if (item.linkList) {
      item.linkList.forEach(link => acc.push(link.url));
    }

    return acc;
  }, []);

  return submenuLinks.some(item => item.includes(currentLink));
}

export default React.memo(MainNav);
