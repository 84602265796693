import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { useLocation } from '@reach/router';

import BurgerBtn from './components/BurgerBtn';
import MobileMenu from './components/MobileMenu';
import MainNav from './components/MainNav';
import LangSwitcher from './components/LangSwitcher/LangSwitcher';
import Link from 'components/UI/Link';
import Button from 'components/UI/Button';
import AnchorMenu from './components/AnchorMenu';
import Phone from './components/Phone';
import { useHistoryCallback } from 'utils/routing';
import { isRequestedFrom, useLang, useTrans } from 'utils/localisation';
import { useHeaderTranslation } from 'staticQueries/header-translation';
import { arabicRegion, germanRegion, azerRegion } from 'define/locale';

import * as styles from './Header.module.scss';

const whiteBgPages = [
  '/industries/financial-services/digital-banking-app',
  '/industries/healthcare/telemedicine-app-development',
];

function Header() {
  const lang = useLang();
  const t = useTrans(['header']);
  const { anchors, links } = useHeaderTranslation();
  const currentLink = useLocation();
  const isIndexPage = currentLink.pathname === '/';
  const isWhiteBg =
    currentLink.pathname.includes('/project-cases') || whiteBgPages.includes(currentLink.pathname);
  const showProgressIndicator = currentLink.pathname.includes('/blueprint');
  const [anchorLinks, setAnchorLinks] = useState(null);
  const [headerFixed, setHeaderFixed] = useState(false);
  const [mobileMenuVisible, setActiveMobileMenu] = useState(false);
  const [enableSwitcher, setEnableSwitcher] = useState(false);
  const showPhone = !headerFixed && currentLink.pathname !== '/contact-us';
  const contactUsLink =
    lang === 'ae' || lang === 'az' ? 'https://andersenlab.com/contact-us' : '/contact-us';

  const toggleMobileMenu = useCallback(() => {
    setActiveMobileMenu(!mobileMenuVisible);
  }, [mobileMenuVisible]);

  const handlerScroll = useCallback(() => {
    const scrollTop = (window.scrollY || document.scrollTop) - (document.clientTop || 0);

    scrollTop > 20 ? setHeaderFixed(true) : setHeaderFixed(false);
  }, []);

  const scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    const heightPx = scrollHeight - clientHeight;
    const scrolled = (scrollPx / heightPx) * 100;

    const progress = document.getElementById('progressBar');
    if (progress) {
      progress.style.width = `${scrolled}%`;
    }
  };

  useEffect(() => {
    if (lang === 'ae' || lang === 'az' || lang === 'de' || isRequestedFrom(germanRegion)) {
      setEnableSwitcher(true);
    }
    if (lang === 'en' && isIndexPage) {
      if (isRequestedFrom(arabicRegion) || isRequestedFrom(azerRegion)) {
        setEnableSwitcher(true);
      }
    }
    if (lang === 'en' && !isIndexPage) {
      if (isRequestedFrom(arabicRegion) || isRequestedFrom(azerRegion)) {
        setEnableSwitcher(false);
      }
    }
  }, [lang, isIndexPage]);

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll, { passive: true });
    handlerScroll();
    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, []);

  useEffect(() => {
    if (showProgressIndicator) {
      window.addEventListener('scroll', scrollProgress, { passive: true });
      scrollProgress();
      return () => {
        window.removeEventListener('scroll', scrollProgress);
      };
    }
  }, []);

  useEffect(() => {
    const redactedCurrentLink = currentLink.pathname.endsWith('/')
      ? currentLink.pathname.slice(0, -1)
      : currentLink.pathname;
    const currentAnchorLinks = anchors?.find(({ url }) => url === redactedCurrentLink);

    if (currentAnchorLinks) {
      setAnchorLinks(currentAnchorLinks.links);
    } else {
      setAnchorLinks(null);
    }
  }, [currentLink.pathname]);

  useHistoryCallback(() => {
    setActiveMobileMenu(false);
  });

  return (
    <header className={styles.header}>
      <div className={styles.topLine}>
        <div className={styles.topLineLayout}>
          <Link href="/" className={cn(styles.logoWrap, mobileMenuVisible && styles.hidden)}>
            <div className={styles.logo} aria-label="Andersen logo" role="img" />
          </Link>
          <div className={styles.mobilePanel}>
            <BurgerBtn
              fixed={mobileMenuVisible}
              active={mobileMenuVisible}
              onClick={toggleMobileMenu}
            />
            <MobileMenu links={links} open={mobileMenuVisible} enableSwitcher={enableSwitcher} />
          </div>
          <nav className={styles.navigation}>
            <MainNav
              items={links}
              mods={['lastItemNoPadding']}
              currentLink={currentLink.pathname}
              isIndexPage={isIndexPage}
            />
            <div className={styles.contactUsWrapper}>
              {enableSwitcher && (
                <div className={styles.item}>
                  <LangSwitcher isIndexPage={isIndexPage} />
                </div>
              )}
              <Button tag="a" size="sm" href={contactUsLink} theme="header">
                {t('contactUsDesktop')}
              </Button>
            </div>
          </nav>
        </div>
        <div className={cn(styles.communicationWrapper, !showPhone && styles.hidePhone)}>
          <Phone lang={lang} isWhiteBg={isWhiteBg} />
        </div>
        {anchorLinks && <AnchorMenu anchorLinks={anchorLinks} headerFixed={headerFixed} />}
      </div>
      {showProgressIndicator && (
        <div className={styles.progressContainer}>
          <div className={styles.progressbar} id="progressBar" />
        </div>
      )}
    </header>
  );
}

export default Header;
