import React from 'react';
import cn from 'classnames';
import ImageLink from './ImageLink';
import { useHeaderArticles } from 'staticQueries/header-articles';
import { useHeaderArticlesDe } from 'staticQueries/header-articles-de';
import { useLang } from 'utils/localisation';

import * as styles from './TabsWithLinks.module.scss';

export default function ArticleCards() {
  const lang = useLang();
  const articles = useHeaderArticles();
  const articlesDe = useHeaderArticlesDe();

  const preparedArticles = lang === 'de' ? articlesDe : articles;

  return (
    <div className={cn(styles.linksWrap, styles.imagesWrap)}>
      {preparedArticles?.map(({ previewTitle, articleLink, previewImage }, i) => {
        return (
          <ImageLink
            key={i}
            title={previewTitle}
            url={articleLink}
            img={{ desktop: previewImage.url }}
          />
        );
      })}
    </div>
  );
}
